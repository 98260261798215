
  import { defineComponent, reactive, PropType, toRefs, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';
  import { required, helpers } from "@vuelidate/validators";

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IErrorFields, IFamilyAttributes } from '@/types';
  import { clearErrorField, handleSetErrors, validateField, formatPhoneNumber } from '@/core/helper-functions';
  import { familyFormRules } from '@/views/admin/families/validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'FamilyForm',

    components: { AppInput, AppButton, AppIcon },

    props: {
      family: {
        type: Object as PropType<IFamilyAttributes>,
        required: true
      },

      originalFamilyData: {
        type: Object as PropType<IFamilyAttributes>,
        default: null
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

    },

    emits: ['submit', 'update'],

    setup(props, { emit }) {
      const { family, originalFamilyData } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        firstname: '',
        lastname: '',
        phoneNumber: '',
        charityTrackingId: '',
        zipCode: ''
      });

      // Validation rules, added required to phone number if its already exits, not able to remove it in that case[Problem with AWS Cognito]
      const validationRules = computed(() => (
        {
          ...familyFormRules,
          phoneNumber: originalFamilyData.value.phoneNumber
            ? {
              ...familyFormRules.phoneNumber,
              required: helpers.withMessage('This field cannot be empty', required)
            }
            : familyFormRules.phoneNumber
        }
      ));

      const v$ = useVuelidate(validationRules, family.value);

      // Check equality and format number if exits
      const disabledAction = computed<boolean>(() => isEqual(
        {
          ...family.value,
          phoneNumber: family.value.phoneNumber
            ? formatPhoneNumber(family.value.phoneNumber)
            : ""
        }, originalFamilyData.value)
      );

      async function onSubmit() {
        if (await v$.value.$validate()) {
          emit('submit', {
            ...family.value,
            phoneNumber: family.value.phoneNumber
              ? formatPhoneNumber(family.value.phoneNumber)
              : "",
            zipCode: family.value.zipCode ? family.value.zipCode : "",
            charityTrackingId: family.value.charityTrackingId ? family.value.charityTrackingId : "",
          });
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,
        disabledAction,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
