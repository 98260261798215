import { required, helpers, email } from "@vuelidate/validators";
import { validateLength } from '@/core/helper-functions';

export const familyFormRules = {
  email: { 
    required: helpers.withMessage('This field cannot be empty', required),
    email: helpers.withMessage('Email address is not valid', email)
  },
  firstName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  lastName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  zipCode: {
    validateZipCode: helpers.withMessage('Invalid ZipCode', validateLength(5))
  },
  phoneNumber: {
    validatePhoneNumber: helpers.withMessage('Invalid Phone number', validateLength(17))
  },
  charityTrackingId: {}
};
