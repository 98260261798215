
  import { defineComponent, reactive, toRefs, onMounted, ref, watchEffect } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';

  import FamilyForm from "@/views/admin/families/components/FamilyForm.vue";
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IFamilyAttributes, IFamilyData } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { familiesService, authService } from "@/services";
  import { FAMILIES_PARAMS } from '@/views/admin/families/api-params';
  import { formatPhoneNumber, getChangedData } from '@/core/helper-functions';

  export default defineComponent({
    name: 'UpdateFamily',

    components: { FamilyForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const state = reactive({
        falimyForm: {} as IFamilyAttributes,
        referenceCopyfalimyForm: {} as IFamilyAttributes,
        originalFamilyData: {} as IFamilyAttributes
      });
      const familyId = ref<null | string>(null);
      const disabledHandleChanges = ref<boolean>(false);

      const { falimyForm, originalFamilyData, referenceCopyfalimyForm } = toRefs(state);

      function checkFamilyId() {
        const { query } = route;
        if (!query.id) {
          router.push({ name: routesNames.adminFamiliesList });
        } else {
          familyId.value = query.id as string;
        }
      }

      function setFamilyForm(family: IFamilyAttributes) {
        const form: IFamilyAttributes = {
          email: family.email,
          firstName: family.firstName,
          lastName: family.lastName,
          phoneNumber: family.phoneNumber ? family.phoneNumber : '',
          charityTrackingId: family.charityTrackingId ? family.charityTrackingId : '',
          zipCode: family.zipCode ? family.zipCode : ''
        };

        falimyForm.value = { ...form };
        referenceCopyfalimyForm.value = { ...form };
        originalFamilyData.value = { ...form };
      }

      watchEffect(() => {
        // Need for checking changes
        referenceCopyfalimyForm.value.phoneNumber = formatPhoneNumber(falimyForm.value.phoneNumber || "");
        referenceCopyfalimyForm.value.email = falimyForm.value.email;
        referenceCopyfalimyForm.value.firstName = falimyForm.value.firstName;
        referenceCopyfalimyForm.value.lastName = falimyForm.value.lastName;
        referenceCopyfalimyForm.value.charityTrackingId = falimyForm.value.charityTrackingId || '';
        referenceCopyfalimyForm.value.zipCode = falimyForm.value.zipCode || '';
      });

      async function onSubmit(payload: IFamilyAttributes) {
        vuex.setLoading(true);

        await familiesService.updateFamily(
          familyId.value as string,
          getChangedData(originalFamilyData.value, payload) as IFamilyAttributes
        )
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('User has been updated');
            router.push({ name: routesNames.adminFamiliesList });
            vuex.setLoading(false);
            return authService.getAuthenticatedUser(true);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        await familiesService.fetchFamily(familyId.value as string, FAMILIES_PARAMS)
          .then((res: IFamilyData) => {
            setFamilyForm(res.details.attributes);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            router.push({ name: routesNames.adminFamiliesList });
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => {
        checkFamilyId();
        fetchInitData();
      });

      return {
        falimyForm,
        referenceCopyfalimyForm,
        originalFamilyData,
        disabledHandleChanges,

        onSubmit
      };
    }

  });
