<template>
  <!-- A C T I O N  B U T T O N S -->
  <div class="mb-12 flex justify-between">
    <div
      class="flex items-center mb-12 text-grey-fp-50 cursor-pointer"
      @click="router.push({ name: routesNames.adminFamiliesList })"
    >
      <AppIcon
        name="arrow-thin-left"
        class="mr-10"
      />
      <p class="text-md">Back to Families list</p>
    </div>

    <div class="flex items-start justify-end">
      <AppButton
        type="primary"
        class="mr-10 px-30"
        :disabled="disabledAction || v$.$invalid"
        @click="onSubmit"
      >
        Update
      </AppButton>
    </div>
  </div>

  <!-- F O R M -->
  <div class="py-10">
    <form
      class="max-w-625"
      @submit.prevent="onSubmit"
    >
      <div class="max-w-350">
        <AppInput
          v-model="v$.firstName.$model"
          label="First name"
          name="firstName"
          class="mb-20"
          maxlength="100"
          :error="errorMessages.firstName"
          @focus="clearErrorField('firstName')"
          @blur="validateField('firstName')"
        />

        <AppInput
          v-model="v$.lastName.$model"
          label="Last name"
          name="lastName"
          class="mb-20"
          maxlength="100"
          :error="errorMessages.lastName"
          @focus="clearErrorField('lastName')"
          @blur="validateField('lastName')"
        />

        <AppInput
          v-model="v$.email.$model"
          label="Email"
          name="email"
          class="mb-20"
          disabled
          maxlength="100"
        />

        <AppInput
          v-model="v$.phoneNumber.$model"
          name="phoneNumber"
          label="Phone number"
          mask="+1 (###) ###-####"
          placeholder="+1 (777) 777-7777"
          class="mb-20"
          :error="errorMessages.phoneNumber"
          @focus="clearErrorField('phoneNumber')"
          @blur="validateField('phoneNumber')"
        />

        <AppInput
          v-model="v$.zipCode.$model"
          name="zipCode"
          class="mb-20"
          mask="#####"
          placeholder="00000"
          :error="errorMessages.zipCode"
          @focus="clearErrorField('zipCode')"
          @blur="validateField('zipCode')"
        >
          <template #label>
            <p class="text-dark-cl-20">Zip code <span class="text-grey-fp-60">(Optional)</span></p>
          </template>
        </AppInput>

        <AppInput
          v-model="v$.charityTrackingId.$model"
          name="charityId"
          class="mb-20"
          maxlength="140"
          :error="errorMessages.charityTrackingId"
          @focus="clearErrorField('charityTrackingId')"
        >
          <template #label>
            <p class="text-dark-cl-20">Charity Tracker ID <span class="text-grey-fp-60">(Optional)</span></p>
          </template>
        </AppInput>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, PropType, toRefs, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';
  import { required, helpers } from "@vuelidate/validators";

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IErrorFields, IFamilyAttributes } from '@/types';
  import { clearErrorField, handleSetErrors, validateField, formatPhoneNumber } from '@/core/helper-functions';
  import { familyFormRules } from '@/views/admin/families/validation-rules';
  import { routesNames, router } from '@/router';

  export default defineComponent({
    name: 'FamilyForm',

    components: { AppInput, AppButton, AppIcon },

    props: {
      family: {
        type: Object as PropType<IFamilyAttributes>,
        required: true
      },

      originalFamilyData: {
        type: Object as PropType<IFamilyAttributes>,
        default: null
      },

      mode: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

    },

    emits: ['submit', 'update'],

    setup(props, { emit }) {
      const { family, originalFamilyData } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        firstname: '',
        lastname: '',
        phoneNumber: '',
        charityTrackingId: '',
        zipCode: ''
      });

      // Validation rules, added required to phone number if its already exits, not able to remove it in that case[Problem with AWS Cognito]
      const validationRules = computed(() => (
        {
          ...familyFormRules,
          phoneNumber: originalFamilyData.value.phoneNumber
            ? {
              ...familyFormRules.phoneNumber,
              required: helpers.withMessage('This field cannot be empty', required)
            }
            : familyFormRules.phoneNumber
        }
      ));

      const v$ = useVuelidate(validationRules, family.value);

      // Check equality and format number if exits
      const disabledAction = computed<boolean>(() => isEqual(
        {
          ...family.value,
          phoneNumber: family.value.phoneNumber
            ? formatPhoneNumber(family.value.phoneNumber)
            : ""
        }, originalFamilyData.value)
      );

      async function onSubmit() {
        if (await v$.value.$validate()) {
          emit('submit', {
            ...family.value,
            phoneNumber: family.value.phoneNumber
              ? formatPhoneNumber(family.value.phoneNumber)
              : "",
            zipCode: family.value.zipCode ? family.value.zipCode : "",
            charityTrackingId: family.value.charityTrackingId ? family.value.charityTrackingId : "",
          });
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      return {
        errorMessages,
        router,
        routesNames,

        v$,
        disabledAction,

        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>